<template lang="pug">
om-modal.delete-modal(
  :name="modalName"
  width="600"
  color="light"
  @closeClicked="closeModal"
  @beforeOpen="beforeOpenModal"
)
  template(#header-title)
    h3 {{ $t('SPR.delete.modal.title') }}

  template(#modal-body)
    span {{ $t('SPR.delete.modal.description') }}

  template(#modal-footer)
    .d-flex.justify-content-end.buttons-wrapper
      om-button.cancel-btn(secondary @click="closeModal") {{ $t('cancel') }}
      om-button.ml-2.confirm-btn(primary @click="deleteSPR" :loading="confirmed") {{ $t('sure') }}
</template>
<script>
  import DELETE_SPR_CONFIG from '@/graphql/DeleteSPRConfig.gql';

  export default {
    data: () => ({
      modalName: 'spr-config-delete-modal',
      id: null,
      confirmed: false,
    }),
    methods: {
      async deleteSPR() {
        this.confirmed = true;
        let success;
        try {
          const response = await this.$apollo.mutate({
            mutation: DELETE_SPR_CONFIG,
            variables: {
              id: this.id,
            },
          });

          success = !!response?.data?.result?.success;

          if (!success && response?.errors?.length) {
            console.error('Error during delete SPR config', { errors: response.errors });
          }
        } catch (err) {
          console.error('Caugth error during delete SPR config', {
            msg: err.message,
            stack: err.stack,
          });
        }

        if (success) {
          this.$notify({
            type: 'success',
            text: this.$t('SPR.delete.success'),
          });
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('SPR.delete.failed'),
          });
        }

        this.$bus.$emit('reloadSPRConfigList');
        this.closeModal();
      },
      closeModal() {
        this.id = null;
        this.confirmed = false;
        this.$modal.hide(this.modalName);
      },
      beforeOpenModal({ params: { id } }) {
        this.id = id;
      },
    },
  };
</script>
